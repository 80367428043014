import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { REPORTS_URL, BILLINGS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  isLoading: false,
  response_additional: [],
  response_voice_balance: [],
})

export const getters = {
  getField,
}

export const actions = {
  getAdditionalPackage({ commit }, params) {
    commit('UPDATE_LOADING', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${REPORTS_URL}/billing/additional-balance`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_DATA', res.data)
            commit('UPDATE_LOADING', false)
            resolve(1)
          },
          (err) => {
            resolve(0)
            reject(err)
          }
        )
    })
  },
  getVoiceBalance({ commit }, params) {
    commit('UPDATE_LOADING', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/voice_info`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_DATA_VOICE', { status: 'resolved', ...res.data })
            commit('UPDATE_LOADING_VOICE', false)
            resolve(true)
          },
          (err) => {
            commit('UPDATE_DATA_VOICE', { status: 'rejected' })
            commit('UPDATE_LOADING', false)
            resolve(0)
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  UPDATE_LOADING(state, payload) {
    state.isLoading = payload
  },
  UPDATE_DATA(state, payload) {
    state.response_additional = payload
  },
  UPDATE_LOADING_VOICE(state, payload) {
    state.isLoading = payload
  },
  UPDATE_DATA_VOICE(state, payload) {
    state.response_voice_balance = payload
  },
}
